<template>
  <a-modal
    :visible="showModal"
    :width="960"
    :destroyOnClose="true"
    @cancel="handleCancel"
    style="padding: 10px"
  >
    <template v-slot:title>
      <span style="font-size: 13px">{{ getHandleType() }}图像数据库</span>
    </template>
    <template v-slot:closeIcon>
      <a-icon
        type="close"
        style="
          color: #3c4a54;
          font-size: 12px;
          margin-top: 14px;
          margin-right: 14px;
          float: right;
        "
      />
    </template>

    <template v-slot:footer>
      <div style="">
        <a-button type="primary" style="" @click="saveReport" v-if="!isView"
          >保存</a-button
        >
        <a-button style="" @click="closeReport">关闭</a-button>
      </div>
    </template>
    <div class="main">
      <a-spin :spinning="loading">
        <a-form
          :labelCol="{ span: 8 }"
          :wrapperCol="{ span: 16 }"
          style="margin: 5px"
        >
        </a-form>

        <div class="img">
          <div class="upload">
            <div
              class="upload_warp_left"
              @click="fileClick"
              style="padding: 2px 0px; margin-left: 10px"
              v-if="!isView"
            >
              <a-button
                icon="ios-cloud-upload-outline"
                class="btn btn-success"
                type="primary"
                size="small"
                style="width: 150px; height: 35px"
                >上传图片</a-button
              >
            </div>
            <!--<div class="upload_warp_text" v-if="!isView">
                        选中{{imgList.length}}张文件，共{{bytesToSize(this.size)}}
                    </div>-->
            <div class="upload_warp" style="border: 1px solid white">
              <div class="upload_warp_img" v-show="imgList.length != 0">
                <div
                  class="upload_warp_img_div"
                  v-for="(item, index) of imgList"
                  :key="index"
                >
                  <div class="upload_warp_img_div_top">
                    <!-- <div class="upload_warp_img_div_text" :title="item.file.name">
                                        {{item.file.name}}
                                    </div> -->
                    <a-button
                      icon="delete"
                      class="upload_warp_img_div_del"
                      style="background-color: transparent"
                      @click="fileDel(index)"
                      v-if="!isView"
                    ></a-button>
                  </div>
                  <preview-img :src="item.file.src" />
                  <!-- <img :src="item.file.src"> -->
                </div>
              </div>
              <!-- <div class="" v-show="imgList.length===0">空图片</div> -->
            </div>
            <input
              @change="fileChange($event)"
              type="file"
              id="upload_file"
              multiple
              style="display: none"
            />
          </div>
        </div>

        <template v-slot:title>
          <span style="font-size: 13px">{{ getHandleType() }}图像数据库</span>
        </template>

        <div
          style="
            font-size: 13px;
            height: 31px;
            padding-top: 3px;
            padding-left: 3px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            border-bottom: 1px solid #e8e8e8;
          "
        >
          <span>内容{{ getHandleType() }}</span>
        </div>
        <a-form-model
          ref="form"
          :model="form"
          :rules="rules"
          :labelCol="{ span: 8 }"
          :wrapperCol="{ span: 16 }"
          style="margin: 5px; padding-bottom: 10px"
        >
          <a-row style="padding-top: 10px">
            <!-- <a-col :span="8">
                            <a-form-model-item label="组合：" prop="compose">
                                <a-input v-model="form.compose" placeholder="请输入组合" v-if="!isView"/>
                                <span v-if="isView">{{ form.compose }}</span>
                            </a-form-model-item>
                        </a-col> -->
            <a-col :span="8">
              <a-form-model-item label="样品编号：" prop="sampleNumber">
                <a-input
                  v-model="form.sampleNumber"
                  placeholder="样本编号自动生成不可编辑"
                  :disabled="true"
                  v-if="!isView"
                />
                <a-input
                  v-model="form.sampleNumber"
                  placeholder="样本编号自动生成不可编辑"
                  :disabled="true"
                  v-if="isView"
                />
                <!-- <span v-if="isView">{{ form.sampleNumber }}</span> -->
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="催化剂类型：" prop="catalystType">
                <a-select
                  v-model="form.catalystType"
                  placeholder="请选择催化剂类型"
                  v-if="!isView"
                >
                  <a-select-option key="汽油加氢催化剂" value="汽油加氢催化剂"
                    >汽油加氢催化剂</a-select-option
                  >
                  <a-select-option key="柴油加氢催化剂" value="柴油加氢催化剂"
                    >柴油加氢催化剂</a-select-option
                  >
                  <a-select-option key="渣油加氢催化剂" value="渣油加氢催化剂"
                    >渣油加氢催化剂</a-select-option
                  >
                  <a-select-option key="加氢异构催化剂" value="加氢异构催化剂"
                    >加氢异构催化剂</a-select-option
                  >
                  <a-select-option key="航煤加氢催化剂" value="航煤加氢催化剂"
                    >航煤加氢催化剂</a-select-option
                  >
                  <a-select-option key="r-Al2O3" value="r-Al2O3"
                    >r-Al2O3</a-select-option
                  >
                  <a-select-option key="其他" value="其他"
                    >其他</a-select-option
                  >
                </a-select>
                <a-select
                  v-model="form.catalystType"
                  placeholder="请选择催化剂类型"
                  v-if="isView"
                  :disabled="true"
                >
                  <a-select-option key="汽油加氢催化剂" value="汽油加氢催化剂"
                    >汽油加氢催化剂</a-select-option
                  >
                  <a-select-option key="柴油加氢催化剂" value="柴油加氢催化剂"
                    >柴油加氢催化剂</a-select-option
                  >
                  <a-select-option key="渣油加氢催化剂" value="渣油加氢催化剂"
                    >渣油加氢催化剂</a-select-option
                  >
                  <a-select-option key="加氢异构催化剂" value="加氢异构催化剂"
                    >加氢异构催化剂</a-select-option
                  >
                  <a-select-option key="航煤加氢催化剂" value="航煤加氢催化剂"
                    >航煤加氢催化剂</a-select-option
                  >
                  <a-select-option key="r-Al2O3" value="r-Al2O3"
                    >r-Al2O3</a-select-option
                  >
                  <a-select-option key="其他" value="其他"
                    >其他</a-select-option
                  >
                </a-select>
                <!-- <span v-if="isView">{{ form.catalystType }}</span> -->
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="分析项目：" prop="analyzeProject">
                <a-input
                  v-model="form.analyzeProject"
                  placeholder="请输入分析项目"
                  v-if="!isView"
                />
                <a-input
                  v-model="form.analyzeProject"
                  placeholder="请输入分析项目"
                  v-if="isView"
                  :disabled="true"
                />
                <!-- <span v-if="isView">{{ form.analyzeProject }}</span> -->
              </a-form-model-item>
            </a-col>
            <!--                        <a-col :span="8">
                            <a-form-model-item label="文件名称：" prop="folderName">
                                <a-input v-model="form.folderName" placeholder="文件名称自动生成不可编辑" :disabled="true" v-if="!isView"/>
                                <span v-if="isView">{{ form.folderName }}</span>
                            </a-form-model-item>
                        </a-col>-->
          </a-row>
          <a-row>
            <a-col :span="8">
              <a-form-model-item label="样品状态：" prop="sampleState">
                <a-select
                  v-model="form.sampleState"
                  placeholder="请选择样本状态"
                  v-if="!isView"
                >
                  <a-select-option key="新鲜剂" value="新鲜剂"
                    >新鲜剂</a-select-option
                  >
                  <a-select-option key="待生剂" value="待生剂"
                    >待生剂</a-select-option
                  >
                  <a-select-option key="再生剂" value="再生剂"
                    >再生剂</a-select-option
                  >
                </a-select>
                <a-select
                  v-model="form.sampleState"
                  placeholder="请选择样本状态"
                  v-if="isView"
                  :disabled="true"
                >
                  <a-select-option key="新鲜剂" value="新鲜剂"
                    >新鲜剂</a-select-option
                  >
                  <a-select-option key="待生剂" value="待生剂"
                    >待生剂</a-select-option
                  >
                  <a-select-option key="再生剂" value="再生剂"
                    >再生剂</a-select-option
                  >
                </a-select>
                <!-- <span v-if="isView">{{ form.sampleState }}</span> -->
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item
                label="样品处理条件："
                prop="processingCondition"
              >
                <a-input
                  v-model="form.processingCondition"
                  placeholder="请输入样品处理条件"
                  v-if="!isView"
                />
                <a-input
                  v-model="form.processingCondition"
                  placeholder="请输入样品处理条件"
                  v-if="isView"
                  :disabled="true"
                />
                <!-- <span v-if="isView">{{ form.processingCondition }}</span> -->
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="样品类型：" prop="sampleType">
                <a-select
                  v-model="form.sampleType"
                  placeholder="请选择样品类型"
                  v-if="!isView"
                >
                  <a-select-option key="条纹相" value="条纹相"
                    >条纹相</a-select-option
                  >
                  <a-select-option key="三氧化二铝棒状" value="三氧化二铝棒状"
                    >三氧化二铝棒状</a-select-option
                  >
                  <a-select-option
                    key="三氧化二铝孔隙率"
                    value="三氧化二铝孔隙率"
                    >三氧化二铝孔隙率</a-select-option
                  >
                </a-select>
                <a-select
                  v-model="form.sampleType"
                  placeholder="请选择样品类型"
                  v-if="isView"
                  :disabled="true"
                >
                  <a-select-option key="条纹相" value="条纹相"
                    >条纹相</a-select-option
                  >
                  <a-select-option key="三氧化二铝棒状" value="三氧化二铝棒状"
                    >三氧化二铝棒状</a-select-option
                  >
                  <a-select-option
                    key="三氧化二铝孔隙率"
                    value="三氧化二铝孔隙率"
                    >三氧化二铝孔隙率</a-select-option
                  >
                </a-select>
                <!-- <span v-if="isView">{{ form.sampleType }}</span> -->
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">
              <a-form-model-item label="样品名称：" prop="sampleName">
                <a-input
                  v-model="form.sampleName"
                  placeholder="请输入样品名称"
                  v-if="!isView"
                />
                <a-input
                  v-model="form.sampleName"
                  placeholder="请输入样品名称"
                  v-if="isView"
                  :disabled="true"
                />
                <!-- <span v-if="isView">{{ form.sampleName }}</span> -->
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="其他物性数据：" prop="propertyData">
                <a-input
                  v-model="form.propertyData"
                  placeholder="请输入其他物性数据"
                  v-if="!isView"
                />
                <a-input
                  v-model="form.propertyData"
                  placeholder="请输入其他物性数据"
                  v-if="isView"
                  :disabled="true"
                />
                <!-- <span v-if="isView">{{ form.propertyData }}</span> -->
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="组成：" prop="consist">
                <a-input
                  v-model="form.consist"
                  placeholder="请输入组成"
                  v-if="!isView"
                />
                <a-input
                  v-model="form.consist"
                  placeholder="请输入组成"
                  v-if="isView"
                  :disabled="true"
                />
                <!-- <span v-if="isView">{{ form.consist }}</span> -->
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">
              <a-form-model-item label="送检人：" prop="inspectionPerson">
                <a-input
                  v-model="form.inspectionPerson"
                  placeholder="请输入送检人"
                  v-if="!isView"
                />
                <a-input
                  v-model="form.inspectionPerson"
                  placeholder="请输入送检人"
                  v-if="isView"
                  :disabled="true"
                />
                <!-- <span v-if="isView">{{ form.inspectionPerson }}</span> -->
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item
                label="送检人电话："
                prop="inspectionPersonPhone"
              >
                <a-input
                  v-model="form.inspectionPersonPhone"
                  placeholder="请输入送检人电话"
                  v-if="!isView"
                />
                <a-input
                  v-model="form.inspectionPersonPhone"
                  placeholder="请输入送检人电话"
                  v-if="isView"
                  :disabled="true"
                />
                <!-- <span v-if="isView">{{ form.inspectionPersonPhone }}</span> -->
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="送检时间：" prop="inspectionPersonTime">
                <a-date-picker
                  v-model="form.inspectionPersonTime"
                  show-time
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  v-if="!isView"
                />
                <a-date-picker
                  v-model="form.inspectionPersonTime"
                  show-time
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  v-if="isView"
                  :disabled="true"
                />
                <!--                          <a-input v-model="form.inspectionPersonTime" placeholder="请输入送检时间" v-if="!isView"/>-->
                <!-- <span v-if="isView">{{ form.inspectionPersonTime }}</span> -->
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="8">
              <a-form-model-item label="厂商：" prop="catalystFirm">
                <a-input
                  v-model="form.catalystFirm"
                  placeholder="请输入厂商"
                  v-if="!isView"
                />
                <a-input
                  v-model="form.catalystFirm"
                  placeholder="请输入厂商"
                  v-if="isView"
                  :disabled="true"
                />
                <!-- <span v-if="isView">{{ form.catalystFirm }}</span> -->
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="备注：" prop="remark">
                <a-input
                  v-model="form.remark"
                  placeholder="请输入备注"
                  v-if="!isView"
                />
                <a-input
                  v-model="form.remark"
                  placeholder="请输入备注"
                  v-if="isView"
                  :disabled="true"
                />
                <!-- <span v-if="isView">{{ form.remark }}</span> -->
              </a-form-model-item>
            </a-col>
            <a-col :span="8">
              <a-form-model-item label="送检科室：" prop="inspectionDepartment">
                <a-input
                  v-model="form.inspectionDepartment"
                  placeholder="请输入送检科室"
                  v-if="!isView"
                />
                <a-input
                  v-model="form.inspectionDepartment"
                  placeholder="请输入送检科室"
                  v-if="isView"
                  :disabled="true"
                />
                <!-- <span v-if="isView">{{ form.consist }}</span> -->
              </a-form-model-item>
            </a-col>
          </a-row>
          <!-- <a-row>
                        <a-col :span="8">
                            <a-form-model-item label="赋值状态：" prop="verified">
                                <a-select v-model="form.verified" placeholder="赋值状态" v-if="!isView">
                                    <a-select-option key="已赋值" value="已赋值">已赋值</a-select-option>
                                    <a-select-option key="未赋值" value="未赋值">未赋值</a-select-option>
                                </a-select>
                                <span v-if="isView">{{ form.verified }}</span>
                            </a-form-model-item>
                        </a-col>
                    </a-row> -->
        </a-form-model>
      </a-spin>
    </div>
  </a-modal>
</template>

<script>
import {
  addData,
  updateData,
  queryDataById,
  deleteImageById,
} from "@/api/image/database";
import { message, Modal } from "ant-design-vue";
import PreviewImg from "./components/PreviewImg";

export default {
  name: "DataDialog",
  components: { PreviewImg },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    reportData: {
      type: Object,
      default: null,
    },
    handelType: {
      type: String,
      default: "add",
    },
  },

  data() {
    return {
      loading: false,
      form: {},
      imgList: [],
      backImgList: [],
      size: 0,
      rules: {
        // sampleState: [
        //     { required: true, message: "样品状态不能为空", trigger: ["change", "blur"] }
        // ],
        // sampleNumber: [
        //     { required: true, message: "样品编号不能为空", trigger: ["change", "blur"]}
        // ],
        catalystType: [
          {
            required: true,
            message: "催化剂类型不能为空",
            trigger: ["change", "blur"],
          },
        ],
        // processingCondition: [
        //     { required: true, message: "样品处理条件不能为空", trigger: ["change", "blur"]}
        // ],
        sampleType: [
          {
            required: true,
            message: "样品类型不能为空",
            trigger: ["change", "blur"],
          },
        ],
        // sampleName: [
        //     { required: true, message: "样品名称不能为空", trigger: ["change", "blur"]}
        // ],
        // propertyData: [
        //     { required: true, message: "其他物理数据不能为空", trigger: ["change", "blur"]}
        // ],
        // consist: [
        //     { required: true, message: "组成不能为空", trigger: ["change", "blur"]}
        // ],
        // catalystFirm: [
        //     { required: true, message: "厂商不能为空", trigger: ["change", "blur"]}
        // ],
      },
      isView: false,
    };
  },
  created() {
    if (this.reportData) {
      Object.keys(this.reportData).map((ele) => {
        if (this.reportData[ele] !== "null") {
          this.form[ele] = this.reportData[ele];
        }
      });
    }
  },
  watch: {
    showModal(value) {
      if (this.showModal) {
        this.imgList = [];
        this.isView = this.handelType === "view";
        if (!this.reportData.id && this.reportData.sampleNumber) {
          this.form = { ...this.reportData };
        } else {
          this.form = {};
          this.queryDataById();
        }
      }
    },
  },
  methods: {
    // 获取详情数据
    queryDataById() {
      if (!this.reportData.id) {
        return;
      }
      this.loading = true;
      queryDataById(this.reportData.id).then((res) => {
        this.loading = false;
        if (res.success) {
          this.form = res.data;
          this.imgList = res.data?.imageList.map((ele) => {
            const file = {
              id: ele.id,
              name: ele.imageName,
              src: "data:image/jpeg;base64," + ele.orgImageCode,
            };
            return {
              ...ele,
              file,
            };
          });
        }
      });
    },
    fileClick() {
      // 上传按钮
      document.getElementById("upload_file").click();
    },
    fileChange(el) {
      if (!el.target.files[0].size) return;
      this.fileList(el.target.files);
      el.target.value = "";
    },
    fileList(files) {
      for (let i = 0; i < files.length; i++) {
        this.fileAdd(files[i]);
      }
    },
    fileAdd(file) {
      this.size = this.size + file.size; //总大小
      let reader = new FileReader();
      reader.vue = this;
      reader.readAsDataURL(file);
      reader.onload = function () {
        file.src = this.result;
        this.vue.imgList.push({
          file,
        });
      };
    },
    async fileDel(index) {
      const that = this;
      const item = this.imgList[index];
      Modal.confirm({
        title: "提示",
        content: "是否确认删除该图片？",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          if (item.id) {
            deleteImageById(item.id).then((res) => {
              if (res.success) {
                that.size = that.size - (that.imgList[index].file.size || 0); //总大小
                that.imgList.splice(index, 1);
              }
            });
          } else {
            that.size = that.size - (that.imgList[index].file.size || 0); //总大小
            that.imgList.splice(index, 1);
          }
        },
      });
    },
    bytesToSize(bytes) {
      if (bytes === 0) return "0 B";
      let k = 1000, // or 1024
        sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
      return (bytes / Math.pow(k, i)).toPrecision(3) + " " + sizes[i];
    },
    dragenter(el) {
      el.stopPropagation();
      el.preventDefault();
    },
    dragover(el) {
      el.stopPropagation();
      el.preventDefault();
    },
    drop(el) {
      el.stopPropagation();
      el.preventDefault();
      this.fileList(el.dataTransfer.files);
    },

    handleChange({ uploaderFileList }) {
      this.uploadedFileList = uploaderFileList;
    },

    saveReport(res) {
      if (this.imgList.length === 0) {
        message.error("未上传图片");
        return;
      }
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const formData = new FormData();
          const keys = [
            "id",
            "compose",
            "sampleState",
            "sampleNumber",
            "catalystType",
            "processingCondition",
            "sampleType",
            "propertyData",
            "consist",
            "catalystFirm",
            "remark",
            "inspectionDepartment",
            "verified",
            "folderName",
            "sampleName",
            "inspectionPerson",
            "inspectionPersonPhone",
            "inspectionPersonTime",
            "analyzeProject",
          ];
          for (let key in this.form) {
            if (keys.indexOf(key) > -1 && this.form[key]) {
              formData.append(key, this.form[key]);
            }
          }
          for (let i = 0; i < this.imgList.length; i++) {
            if (this.imgList[i].file && !this.imgList[i].file?.id) {
              formData.append("images", this.imgList[i].file);
            }
          }
          if (this.reportData.isLink) {
            formData.append("type", 1);
            formData.append("fileToDoId", this.reportData.fileToDoId);
          } else {
            formData.append("type", 0);
          }
          const res =
            this.form.id && !this.reportData.isLink
              ? await updateData(formData)
              : await addData(formData);
          this.loading = false;
          if (res.success) {
            message.success("操作成功");
            this.handleCancel();
            this.$emit("onFresh");
          }
        }
      });
    },

    closeReport() {
      // 关闭新增页面
      this.handleCancel();
    },

    handleCancel() {
      this.$parent.closeReport();
      this.loading = false;
      this.imgList = [];
      (this.backImgList = []), (this.size = 0);
    },
    getHandleType() {
      if (this.handelType === "add") {
        return "新增";
      }
      if (this.handelType === "update") {
        return "编辑";
      }
      if (this.handelType === "view") {
        return "查看";
      }
    },
  },
};
</script>

<style scoped>
.dialogTitle {
  background: lightgray;
  font-size: 12px;
}
.main {
  background: white;
}

.subtitle {
  font-weight: bolder;
  font-size: 24px;
  text-align: center;
  color: black;
}

.head {
  height: 61px;
  background: url("../assets/banner.png") no-repeat;
  background-size: 224px 61px;
}

/deep/ .ant-modal-content {
  padding: 10px;
}
/deep/ .ant-modal-header {
  /* background: lightgray; */
  height: 31px;
  padding-top: 3px;
  padding-left: 6px;
  border-bottom: 1px solid #eeeeee;
}
/deep/ .ant-modal-body {
  padding: 0px;
}
/deep/ .ant-row {
  margin: 0px;
  padding: 0px;
}
.upload-list-inline >>> .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline >>> .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline >>> .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
.upload_warp_img_div_del {
  position: absolute;
  width: 16px;
  right: 8px;
  left: 50%;
}
.upload_warp_img_div_top {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.4);
  line-height: 30px;
  text-align: left;
  color: #fff;
  font-size: 12px;
  text-indent: 4px;
}
.upload_warp_img_div_text {
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload_warp_img_div img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.upload_warp_img_div {
  position: relative;
  height: 100px;
  width: 120px;
  border: 1px solid #ccc;
  margin: 0px 5px 5px 0px;
  float: left;
  line-height: 100px;
  display: table-cell;
  text-align: center;
  background-color: #eee;
  cursor: pointer;
}

.upload_warp_img {
  /* border-top: 1px solid #D2D2D2; */
  padding: 5px 0 0 5px;
  overflow: hidden;
}

.upload_warp_text {
  text-align: left;
  margin-bottom: 5px;
  padding-top: 5px;
  text-indent: 14px;
  border-top: 1px solid #ccc;
  font-size: 14px;
}

.upload_warp_right {
  float: left;
  width: 57%;
  margin-left: 2%;
  height: 100%;
  border: 1px dashed #999;
  border-radius: 4px;
  line-height: 130px;
  color: #999;
}
.upload_warp_left button {
  margin: 1px 5px 0px 0px;
  cursor: pointer;
}
.upload_warp_left {
  float: left;
}
.upload_warp_left .btn-success {
  background: #4f60ba;
  color: #fff;
}
.upload_warp {
  margin: 5px;
  clear: both;
}
.upload {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 1px 0px #ccc;
  border-radius: 4px;
}
.img {
  width: 100%;
  text-align: center;
  box-shadow: 0px 1px 6px 1px rgba(61, 74, 148, 0.5);
  border-radius: 4px;
}
/* 查看 */
.lookPng {
  margin-top: 20px;
  min-height: 30px;
  height: 100%;
}
/* 关闭按钮 */
.anticon {
  background: white;
}
.img .ant-btn {
  color: white;
  border: none;
}
</style>
